export function init() {
  var initialized = window.prgs_bar_initialized;
  var bar = document.getElementById('js-prgs-bar');

  if (!bar) return;

  var hamburger = document.getElementById('js-hamburger');
  var drawer = document.getElementById('js-drawer');
  var desktopSearchBtn = document.getElementById('js-search-trigger');
  var desktopUserBtn = document.getElementById('js-user-trigger');
  var mobileSearch = document.getElementById('js-mobsearch');
  var mobileSearchBtn = document.getElementById('js-mobsearch-trigger');
  var dropdownBtns = document.getElementsByClassName('PRGS-Bar-dropdown');
  var dropdownLinks = document.getElementsByClassName('has-dropdown-arrow');
  var dropdowns = document.getElementsByClassName('is-dropdown');
  var skipLink = document.getElementById('js-prgs-nav-skip-link');
  var skipLinkDestination = document.getElementById('site-content');

  var search = {
    input: document.getElementById('js-search-input'),
    mobinput: document.getElementById('js-search-mobinput'),
    submit: document.getElementById('js-search-submit'),
    mobsubmit: document.getElementById('js-search-mobsubmit')
  };

  var HOSTNAME = window.location.hostname;
  var SEARCH_URL = search.input.getAttribute('data-url');

  function escape(html) {
    return html ? document.createElement('div').appendChild(document.createTextNode(html)).parentNode.innerHTML : html;
  }

  function encode(str) {
    var replace = {
      '!': '%21',
      '\'': '%27',
      '(': '%28',
      ')': '%29',
      '~': '%7E',
      '%20': '+',
      '%00': '\x00'
    };

    /* eslint no-useless-escape: "off" */
    return encodeURIComponent(str).replace(/[!'\(\)~]|%20|%00/g, function(match) {
      return replace[match];
    });
  }

  function searchInProgress(e, field) {
    field = field || this.previousElementSibling;
    var value = encode(escape(field.value));
    var href = SEARCH_URL.concat('?q=').concat(value);
    value ? window.location.href = href : field.focus();
    e.preventDefault();
  }

  function searchInPartnerLink(e, field) {
    field = field || this.previousElementSibling;
    var value = encode(escape(field.value));
    var href = SEARCH_URL.concat('?indexCatalogue=partnerportalsearch&searchQuery=').concat(value);
    value ? window.location.href = href : field.focus();
    e.preventDefault();
  }

  function performSearch(e, field) {
    field = field || this.previousElementSibling;

    if (HOSTNAME.indexOf('partnerlink') >= 0) {
      searchInPartnerLink(e, field);
    } else {
      searchInProgress(e, field);
    }
  }

  function searchOnEnter(e) {
    if (e.keyCode === 13) {
      window.requestAnimationFrame(function() {
        performSearch(e, e.target);
      });
    }
  }

  function isChildOf(child, parent) {
    if (child.parentNode === parent) {
      return true;
    } else if (child.parentNode === null) {
      return false;
    } else {
      return isChildOf(child.parentNode, parent);
    }
  }

  function clickOutsideOfMobileMenu(e) {
    var target = e.target || e.srcElement;
    if (
      (target !== drawer && !isChildOf(target, drawer) ) &&
      (target !== hamburger && !isChildOf(target, hamburger))
    ) {
      drawer.classList.remove('is-visible');
      document.body.classList.remove('is-mobilemenu-visible');
    } else {
      e.stopPropagation();
    }
  }

  function clickOutsideOfSearchMobileMenu(e) {
    var target = e.target || e.srcElement;
    if (
      (target !== mobileSearch && !isChildOf(target, mobileSearch) ) &&
      (target !== mobileSearchBtn && !isChildOf(target, mobileSearchBtn))
    ) {
      mobileSearch.classList.remove('is-visible');
      document.body.classList.remove('is-mobilesearch-visible');
    } else {
      e.stopPropagation();
    }
  }

  function isDescendant(parent, child) {
    var node = child.parentNode;
    while (node != null) {
      if (node == parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }

  function focusInput() {
    search.input.focus();
    search.mobinput.focus();
  }

  function toggleDrawer() {
    drawer.classList.toggle('is-visible');
    document.body.classList.toggle('is-mobilemenu-visible');
  }

  function toggleMobileSearch(e) {
    mobileSearch.classList.toggle('is-visible');
    document.body.classList.toggle('is-mobilesearch-visible');
    window.setTimeout(focusInput, 200);
  }

  function closeMobileMenus(e) {
    clickOutsideOfMobileMenu(e);
    clickOutsideOfSearchMobileMenu(e);
  }

  function mobileDropdownsToOpenOnClick(e) {
    if ( !isDescendant(this.querySelector('.is-dropdown'), e.target) ) {
      this.classList.toggle('is-tapped');
    }
  }

  function disableDropdownLink(link) {
    link && link.classList.toggle('is-disabled');
  }

  function disableClicksWhileAnimated(e) {
    var target = e.target || e.srcElement;
    var animation = e.propertyName;
    var trigger = target.previousElementSibling || null;
    if (animation === 'transform' && target === this && trigger && typeof trigger.href !== 'undefined') {
      trigger.classList.toggle('is-disabled');
    }
  }

  function closeFocusedDropdown() {
    Array.prototype.slice.call(dropdownLinks).forEach(function(dropdownLink) {
      dropdownLink.classList.remove('is-focused');
    });
    Array.prototype.slice.call(dropdownBtns).forEach(function(dropdownBtn) {
      dropdownBtn.classList.remove('is-open');
    });
  }

  function focusElement(element) {
    element.addEventListener('focus', function(event) {
      closeFocusedDropdown();
      event.target.classList.add('is-focused');

      if (!event.currentTarget.getAttribute('href')) {
        const dropdownWrapper = event.target.closest('.PRGS-Bar-dropdown');
        if (dropdownWrapper) dropdownWrapper.classList.add('is-open');
      }
    });
  }

  function skipToContent(event) {
    event.preventDefault();
    skipLinkDestination.tabIndex = 0;
    skipLinkDestination.focus();
    skipLinkDestination.tabIndex = -1;
  }

  function _init() {
    if (initialized) return;

    search.input.addEventListener('keydown', searchOnEnter);
    search.submit.addEventListener('click', performSearch);
    search.mobinput.addEventListener('keydown', searchOnEnter);
    search.mobsubmit.addEventListener('click', performSearch);
    hamburger.addEventListener('click', toggleDrawer);
    mobileSearchBtn.addEventListener('click', toggleMobileSearch);
    document.addEventListener('click', closeMobileMenus);
    skipLink.addEventListener('click', skipToContent);

    Array.prototype.slice.call(dropdownBtns).forEach(function(dropdownBtn) {
      dropdownBtn.addEventListener('click', mobileDropdownsToOpenOnClick);
      disableDropdownLink(dropdownBtn.querySelector('a'));
    });
    Array.prototype.slice.call(dropdowns).forEach(function(dropdown) {
      dropdown.addEventListener('transitionend', disableClicksWhileAnimated);
    });
    Array.prototype.slice.call(dropdownLinks).forEach(dropdownLink => focusElement(dropdownLink));
    focusElement(desktopUserBtn);

    desktopSearchBtn.addEventListener('click', function() {
      closeFocusedDropdown();
      const dropdownWrapper = this.closest('.PRGS-Bar-dropdown');
      this.classList.add('is-focused');
      dropdownWrapper.classList.add('is-open');
      focusInput();
    });

    document.getElementById('js-close-focused').addEventListener('focus', closeFocusedDropdown);

    document.body.addEventListener('click', function(event) {
      if (event.target !== bar) {
        closeFocusedDropdown();
      }
    }, true);

    bar.addEventListener('keydown', function(event) {
      if (event.target.classList.contains('is-focused') && event.target !== desktopSearchBtn && event.key === 'Enter') {
        const dropdownWrapper = event.target.closest('.PRGS-Bar-dropdown');

        if (!dropdownWrapper.classList.contains('is-open')) {
          event.preventDefault();
          dropdownWrapper.classList.add('is-open');
        } else {
          event.target.trigger('click');
        }
      }
    });

    window.prgs_bar_initialized = true;
  }

  _init();
}
