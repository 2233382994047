import 'classlist-polyfill';

// import 'custom-event-polyfill';
// import closest from 'element-closest';
import 'core-js/features/promise/index';
// import 'core-js/features/array/from';
// import 'core-js/features/array/includes';
// import 'core-js/features/object/assign';
// import 'core-js/features/string/includes';
// import 'core-js/features/string/starts-with';
// import 'whatwg-fetch';

import { init } from './init.mjs';
import { interactive } from './interactive.mjs';

// closest(window);

interactive().then(() => {
  init();
});
