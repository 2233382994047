/**
 * DOM Ready alternative - working with async scripts
 *
 * @return {Promise<void>}
 */
export const interactive = () => {
  return new Promise((resolve) => {
    if (document.readyState !== 'loading') {
      resolve();
    } else {
      document.addEventListener('readystatechange', () => {
        if (document.readyState === 'interactive') {
          resolve();
        }
      });
    }
  });
};
